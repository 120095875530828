(function () {

  // touch?

  var isTouchDevice = 'ontouchstart' in window || navigator.msMaxTouchPoints,
    bodyElement = document.querySelector('body')

  if(isTouchDevice) {
    bodyElement.classList.add('touch')
  } else {
    bodyElement.classList.add('no-touch')
  }

  // get all the things

  var main = document.querySelector('main'),
    contentElement = document.querySelector('main .inner'),
    projects = document.querySelector('#projects'),
    homeLink = document.querySelector('header h1 a'),
    // homePath = '/mjk/',
    homePath = '/',
    homeTitle = homeLink.innerHTML

  // render

  var render = function (res, err) {
    var res = res

    if (err) {
      res = '<h2>Kunde inte hitta sidan :(</h2>'

      document.title = 'Sidan kunde inte hittas | ' + homeTitle

      setTimeout(function () {
        setTimeout(function () {
          contentElement.classList.add('visible')
        }, 100)
      }, 200)
    } else {
      contentElement.classList.remove('visible')

      if (isHome()) {
        document.title = homeTitle
      } else {
        document.title = history.state.title + ' | ' + homeTitle
      }

      setTimeout(function () {
        contentElement.innerHTML = res
        setTimeout(function () {
          if (isHome()) {
            links()
            photoPlugs('#project-links')
          } else {
            photoPlugs('#project-photos')
            addCloseLink()
          }
          contentElement.classList.add('visible')
        }, 100)
      }, 200)
    }
  }

  // request

  var request = function (url) {
    var req = new XMLHttpRequest()

    var loadEventHandler = function (event) {
      var status = event.currentTarget.status,
        res = event.currentTarget.responseText

      if (status === 200) {
        render(res)
      } else if (status === 404) {
        render(res, true)
      }
    }

    req.open('GET', url + 'ajax')
    req.addEventListener('load', loadEventHandler)
    req.send()
  }

  // links

  var links = function () {
    var linksCollection = document.querySelectorAll('#project-links a'),
      links = [].slice.call(linksCollection)

    links.forEach(function (link) {
      link.addEventListener('click', function (event) {
        var url = link.pathname,
          title = link.getAttribute('data-title')

        request(url)

        history.pushState({url: url, title: title}, title, url)

        event.preventDefault()
      })
    })
  }

  // home?

  var isHome = function () {
    if (document.location.pathname === homePath) {
      return true
    }
  }

  // home link

  homeLink.addEventListener('click', function (event) {
    request(homePath)

    history.pushState({url: homePath}, 'Hem', homePath)

    event.preventDefault()
  })

  // add close link

  var addCloseLink = function (init) {
    var link = document.createElement('a')

    link.id = 'close-link'
    link.href = '#'
    link.innerHTML = '<svg width="30" height="30"><g stroke="rgb(30, 30, 30)" stroke-width="4"><line x1="5" y1="5" x2="25" y2="25"></line><line x1="5" y1="25" x2="25" y2="5"></line></g></svg>'

    link.addEventListener('click', function (event) {
      if (init) {
        request(homePath)

        history.pushState({url: homePath}, 'Hem', homePath)
      } else {
        history.back()
      }

      event.preventDefault()
    })

    contentElement.insertBefore(link, contentElement.firstChild)
  }

  // photo plugs

  var photoPlugs = function (id) {
    imagesLoaded(id, function() {
      var masonry = new Masonry(id, {
        itemSelector: '.grid-item',
        percentPosition: true,
        gutter: 20
      })
    })

    if (id === '#project-photos') {
      baguetteBox.run('#project-photos', {
        captions: true
      })
    }
  }

  // popstate

  window.addEventListener('popstate', function (event) {
    request(event.state.url)
  })

  // init stuff

  links()

  history.replaceState({url: document.location.pathname}, 'Init', document.location.pathname)

  contentElement.classList.add('visible')

  if (isHome()) {
    photoPlugs('#project-links')
  } else {
    photoPlugs('#project-photos')
    addCloseLink(true)
  }
})()